import { createRouter, createWebHistory, createWebHashHistory } from "vue-router";

const router = createRouter({
    history:  process.env.VUE_APP_MODE == 'test' ? createWebHashHistory() : createWebHistory(),
    // history: createWebHistory(),
    routes: [
        { 
            name: 'index',
            path: '/',
            title: '授权登录',
            component:()=>import('../view/index'),
            redirect: '/add',
        },
        {
            name: 'add',
            path: '/add',
            title: '绑定手机号',
            component:()=>import('../view/add'),
            meta: {
                title: '绑定手机号'
            }
        },
        {
            name: 'list',
            path: '/list',
            title: '故障单列表',
            component:()=>import('../view/list'),
            meta: {
                title: '故障单列表'
            }
        },
        {
            name: 'renewal',
            path: '/renewal',
            title: '设备续费',
            component:()=>import('../view/renewal'),
            meta: {
                title: '设备续费'
            }
        },
        {
            name: 'detail',
            path: '/detail',
            title: '故障单详情',
            component:()=>import('../view/detail'),
            meta: {
                title: '故障单详情'
            }
        },
        {
            name: 'report',
            path: '/report',
            title: '上报故障单',
            component:()=>import('../view/report'),
            meta: {
                title: '上报故障单'
            }
        },
    ]
})

router.beforeEach((to, from, next) => {
    if (to.meta.title) {
        window.document.title = to.meta.title;
    }
    next();
})
export default router