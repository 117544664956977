import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import service from './axios'
import wxTitle from 'vue-wechat-title'
import 'vant/lib/index.css';
const app = createApp(App)
app.use(router)
app.use(wxTitle)
app.provide('$http',service).mount('#app')
